<template>
  <div>
    <ContentLoading type="app" v-if="!footerPagesList"/>
    <div v-else>
      <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner && !isMobileApp && !noBanner"/>
      <ContentLoading v-else-if="!isMobileApp && !noBanner" type="banner" />

      <ContactUs v-if="pageInfo.ContactUs === slug"/>
      <DestinationGuide v-if="pageInfo.DestinationGuide === slug"/>
      <RequestCancelOrder v-if="pageInfo.RequestCancelOrder === slug"/>
      <SubscribeMailingList v-if="pageInfo.SubscribeMailingList === slug"/>

      <FooterPage v-if="pageContent && !isStaticPage" :page="pageContent" />
      <Footer v-if="!isMobileApp"/>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  name: 'Slug',
  mixins: [getBannerImage],
  components: {
    Banner: () => import('@/components/content/Banner'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Footer: () => import('@/components/content/Footer'),

    DestinationGuide: () => import('@/components/TravelInfoPage/pages/DestinationGuide'),
    ContactUs: () => import('@/components/TravelInfoPage/pages/ContactUs'),
    SubscribeMailingList: () => import('@/components/TravelInfoPage/pages/SubscribeMailingList'),
    RequestCancelOrder: () => import('@/components/TravelInfoPage/pages/RequestCancelOrder'),
    FooterPage: () => import('@/components/TravelInfoPage/pages/FooterPage'),
  },
  computed: {
    ...mapGetters({
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      pageContent: 'GET_FOOTER_PAGE_CONTENT',
      typeChannel: 'GET_TYPE_CHANNEL',
    }),
    slug() {
      return this.$route.params.slug;
    },
    pageCode() {
      const { footerPagesList, slug } = this;
      if (!footerPagesList || !slug) return null;
      const pageListArray = Object.entries(footerPagesList);
      const pageIndex = pageListArray.findIndex((item) => (item[1].slug === slug));
      if (pageIndex === -1) return -1;
      return pageListArray[pageIndex][1].id;
    },
    noBanner() {
      return Boolean(this.$route.query.afterBook);
    },
    isStaticPage() {
      const { pageInfo, slug } = this;
      return pageInfo.ContactUs === slug || pageInfo.DestinationGuide === slug || pageInfo.RequestCancelOrder === slug || pageInfo.SubscribeMailingList === slug;
    },
    pageInfo() {
      return {
        DestinationGuide: 'מדריך_היעדים',
        RequestCancelOrder: 'בקשה_לביטול_הזמנה',
        SubscribeMailingList: 'הרשמה_לרשימת_תפוצה',
        ContactUs: 'צור_קשר',
      };
    },
    isMobileApp() {
      return this.typeChannel.toLowerCase() === VUE_APP_CHANNEL_MOBILE_APP.toLowerCase();
    },
  },
  watch: {
    pageCode() {
      this.getPageContent();
    },
  },
  mounted() {
    window.localStorage.removeItem('landing-url');
    this.getPageContent();
  },
  methods: {
    isNotFound(inx) {
      if (Object.keys(this.pageInfo).findIndex((item) => this.pageInfo[item] === this.slug) === -1 && inx === -1) {
        this.$router.push({ name: '404' });
      }
    },
    getPageContent() {
      const { pageCode } = this;
      if (!pageCode) return null;
      if (pageCode === -1) {
        this.isNotFound(-1);
        return false;
      }
      const response = this.$store.dispatch('FETCH_FOOTER_PAGE_CONTENT', pageCode);
      return response;
    },
  },
};
</script>

<style scoped>
  .flying-advertisement {
    font-family: "ploni";
    font-weight: bold;
    font-size: 3rem;
    text-transform: capitalize;
  }

@media (max-width: 767px) {
  .flying-advertisement {
    font-size: 2rem;
    padding: 5px;
  }
}
</style>
